import React from 'react';
import './App.css';
import { Jumbotron, Container, Row, Col, CardGroup, Card } from 'react-bootstrap';
import MyReferralDetail from './MyReferralDetail';
import logoyoga from './img/logo_yoga_w100.png';

class App extends React.Component {
  referralId = null;

  constructor(props) {
		super(props);

    this.state = {
        descontoUtilizado: false,
        rewardPercentage: 0
      }

    try {
      this.load_indicacao();
    } catch (e) {
        alert(e);
    }
	}

  setRewardPercentage = (RewardPercentage) => {
    this.setState({rewardPercentage: RewardPercentage});
  }

  toogleDesconto = (valor) => {
    if (this.state.descontoUtilizado !== valor)
      this.setState({descontoUtilizado: valor});
  }


  render() {
    return (
      <div>
        <Container>
          <Row>
            <Col>
              <h1 id="minhaIndicacao">
                <img id="logoMCM" src="img/MCM_Logos-01.png" alt="Logotipo MinhaCintaModeladora" width="55%"/>
                <img src={logoyoga} alt="Logo Yoga" width="100px"/>
              </h1>
            </Col>
          </Row>
        </Container>
        <Container>
        <section id="mainPlaceHolder">
            <MyReferralDetail referralId={this.referralId}  descontoUtilizado={this.toogleDesconto} setRewardPercentage={this.setRewardPercentage}/>
            <Jumbotron fluid>
                <Container>
                  <h2 className="nomeLoja">Lojas Físicas <img src={logoyoga} alt="Logo Yoga" width="60px"/></h2>
                  <p>
                    Todas as lojas possuem atendimento especializado, provadores, serviço de ajuste, vagas de estacionamento, serviço de entrega e atendem pelo telefone ou Whatsapp.
                  </p>
                </Container>
            </Jumbotron>
            <CardGroup>
                <Card >
                  <Card.Header bg='secondary'><h4>Yoga Moema</h4></Card.Header>
                  <Card.Body>
                    <Card.Text>                    
                        Rua Gaivota, 1419<br />
                        <small>Próximo ao Metrô Eucaliptos (Linha Lilás)</small><br />
                        <small>Abrir endereço no seu GPS:</small> <a href="https://goo.gl/maps/zsjTw9KwoKi91wvu9" rel="noopener noreferrer" target="_blank"><img src="img/maps.google.com.ico" alt="Icone do Google" width="32px" /></a>&nbsp;&nbsp;&nbsp;&nbsp;
                      <a href="https://www.waze.com/ul?ll=-23.60740500%2C-46.67094000&navigate=yes" rel="noopener noreferrer" target="_blank"><img src="img/waze.png" alt="Icone do Waze" width="36px" /></a><br />
                    </Card.Text>         
                    <h6>Horário de Atendimento</h6>
                    <Card.Text>                    
                        Segunda a Sexta das 10h as 18h<br />
                        Sábado das 10h as 15h
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>Telefone: <strong><a href="http://api.whatsapp.com/send?1=pt_BR&phone=551143015660"><img src="img/whatsapp.png" alt="Icone do Whatsapp" width="32px" /> 11 4301-5660</a></strong>
                  </Card.Footer>
                </Card>

                <Card >
                  <Card.Header bg='secondary'><h4>Yoga Vila Clementino</h4></Card.Header>
                  <Card.Body>
                    <Card.Text>                    
                        Av. Dr. Altino Arantes, 137<br />
                        <small>Próximo ao Metrô Santa Cruz (Linha Azul)</small><br />
                        <small>Abrir endereço no seu GPS:</small> <a href="https://g.page/Yoga-Modeladores-Vila-Clementino?share" rel="noopener noreferrer" target="_blank"><img src="img/maps.google.com.ico" alt="Icone do Google" width="32px" /></a>&nbsp;&nbsp;&nbsp;&nbsp;
                        <a href="https://www.waze.com/ul?ll=-23.60296430%2C-46.63822580&navigate=yes" rel="noopener noreferrer" target="_blank"><img src="img/waze.png" alt="Icone do Waze" width="36px" /></a>
                    </Card.Text>         
                    <h6>Horário de Atendimento</h6>
                    <Card.Text>                    
                        Segunda a Sexta das 10h as 18h<br />
                        Sábado das 10h as 15h
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>Telefone: <strong><a href="http://api.whatsapp.com/send?1=pt_BR&phone=551155874555"><img src="img/whatsapp.png" alt="Icone do Whatsapp" width="32px" /> 11 5587-4555</a></strong></Card.Footer>
                </Card>

                <Card >
                <Card.Header bg='secondary'><h4>Yoga Pacaembu</h4></Card.Header>
                  <Card.Body>
                    <Card.Text>                    
                        Rua Traipu, 527 B<br />
                        <small>Pacaembu, próximo a Perdizes e Higienópolis</small><br />
                    </Card.Text>         
                    <h6>Horário de Atendimento</h6>
                    <Card.Text>                    
                        Segunda a Sexta das 10h as 18h<br />
                        Sábado das 10h as 15h
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>Telefone: <strong><a href="http://api.whatsapp.com/send?1=pt_BR&phone=5511985470177"><img src="img/whatsapp.png" alt="Icone do Whatsapp" width="32px" /> 11 98547-0177</a></strong></Card.Footer>
                </Card>

              </CardGroup>


              <Jumbotron fluid>
                <Container>
                  <h2 className="nomeLoja">Loja Virtual <img id="logoMCM" src="img/MCM_Logos-01.png" alt="Logotipo MinhaCintaModeladora" width="80px"/></h2>
                  <p>
                    Conheça e compre também pela nossa loja virtual.
                  </p>
                  <p>
                    Dica: alguns modelos são mais baratos nas lojas físicas, ou comprando pelo site e optando pela retirada nas lojas acima.
                  </p>
                  <Card >
                  <Card.Body>
                    <Card.Text>                
                      <a href="https://www.minhacintamodeladora.com.br">www.minhacintamodeladora.com.br</a> <br />
                    </Card.Text>  
                  </Card.Body>       
                  <Card.Footer>
                      Envie este QRCode para o WhatsApp da loja virtual e solicite seu Cupom de Desconto para compra no site: <a href=" http://api.whatsapp.com/send?1=pt_BR&phone=551143015669">11 4301-5669</a>
                  </Card.Footer>
            </Card>
                </Container>
            </Jumbotron>


          </section>
        </Container>
      </div>
    );
  }

  load_indicacao = () => {

    const args = window.location.search.substr(1).split(/&/);
    var referralId = "";
    for (let i=0; i<args.length; ++i) {
        const tmp = args[i].split(/=/);
        switch (tmp[0]) {
        case 'hash':
          console.log('Hash:' + tmp[1]);
          break;
        case 'referralid':
          referralId = tmp[1];
          console.log('ReferralId:' + tmp[1]);
          break;
        default:
          break;
        }
    }

    //console.log('load_indicacao:'+referralId);
    this.referralId = referralId;
  }
}
export default App;

import React from 'react';
import awsmobile from './copia-aws-exports';
import { Container, ListGroup } from 'react-bootstrap';
import './MyReferralDetail.css';
import MyReferralDiscount from './MyReferralDiscount';
import { Auth } from 'aws-amplify';
import { API } from "aws-amplify";
import * as queries from './graphql/queries.ts';
import { getProductByReference } from './products/Products';
import ProductsCards from './products/ProductsCards';

//Passar referralId
class MyReferralDetail extends React.Component {
  constructor(props) {
		super(props);
    this.state = {parametroReferral: null
                };
	}

  prazoValidade = 90; //em dias

  async componentDidMount() {
    try {
      this.loadReferralData(this.props.referralId);
    } catch (e) {
        alert(e);
    }
  }
/*
  formatDate(dt) {
    return(dt.slice(8,10)+'/'+dt.slice(5,7)+'/'+dt.slice(0,4)+' '+(dt.slice(11,13)-3)+':'+dt.slice(14,16));
  }
*/
  addDays(date, days) {
    const copy = new Date(date);
    copy.setDate(copy.getDate() + days);
    return copy;
  }

  formatDate(data) {
    try {
      if (data.length===10)
        data=data+' 00:00'
      var date = new Date(data);
      return(date.toLocaleDateString('pt-BR'));
    } catch(e) {
    console.log(e);
    }
  }

  loadReferralData = (referralId) => {
    API.configure(awsmobile);
    Auth.configure(awsmobile);
    Auth.currentCredentials()
      .then(anonymousUser => console.log('data: ', anonymousUser))
      .catch(e => console.log('error: ', e));

    //console.log(referralId);
    API.graphql({
      query: queries.getReferral2,
      variables: { id: referralId },
      authMode: 'AWS_IAM'
      }).then(myReferral => {
        //console.log(myReferral);
        //        this.setState({parametroReferral : myReferral.data.getReferral});
        var arrProducts = [];
        for (var i=0;i<myReferral.data.getReferral.products.items.length;i++) {
             //console.log(myReferral.data.getReferral);
             arrProducts.push(getProductByReference(myReferral.data.getReferral.products.items[i].product.reference)[0]);
        }
        //console.log(myReferral.data.getReferral);
        this.setState({products: arrProducts, parametroReferral : myReferral.data.getReferral});
      }).catch(err => {console.log(err);});
  }

  render() {
//    console.log(this.state.parametroReferral);
    if (this.state.parametroReferral) {
      var dtIndicacao = new Date (this.state.parametroReferral.date);
      var dtValidade = this.addDays(this.state.parametroReferral.date, this.prazoValidade);
      var dtHoje = new Date();
      var vencido = dtValidade < dtHoje;

      return(
        <Container>
          <MyReferralDiscount myReferral={this.state.parametroReferral} descontoUtilizado={this.props.descontoUtilizado} setRewardPercentage={this.props.setRewardPercentage} vencido={vencido} partnerName={this.state.parametroReferral.partner.name}/>        

          <ListGroup>
            <ListGroup.Item variant="light">Data de validade: <h5>{dtValidade.toLocaleString('pt-BR')}</h5></ListGroup.Item>
            <ListGroup.Item variant="light">Data: <h5>{dtIndicacao.toLocaleString('pt-BR')}</h5></ListGroup.Item>
            <ListGroup.Item variant="light">Telefone: <h5>{this.state.parametroReferral.clientPhone}</h5></ListGroup.Item>
            <ListGroup.Item variant="light">Linha: <h5>{this.state.parametroReferral.linha}</h5></ListGroup.Item>
            <ListGroup.Item variant="dark">Obs:</ListGroup.Item>
            <ListGroup.Item variant="light"><h5>{this.state.parametroReferral.obs}</h5></ListGroup.Item>
            <ListGroup.Item variant="dark">Produtos:</ListGroup.Item>
          </ListGroup>
          <ProductsCards products={this.state.products}></ProductsCards>
        </Container>
      );
    }
    else
      return('');
        
  }

}
export default MyReferralDetail;

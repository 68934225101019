// tslint:disable
// this is an auto generated file. This will be overwritten

export const getPartner = `query GetPartner($id: ID!) {
  getPartner(id: $id) {
    id
    sub
    name
    phone
    email
    birthday
    gender
    referrals {
      items {
        id
        date
        clientName
        clientPhone
        linha
        obs
        rewardPercentage
        clientDiscount
        clientBonus
      }
      nextToken
    }
    type
    salesman {
      id
      name
      mainstore {
        id
        name
        address
        phone
        whatsapp
        email
        obs
      }
      active
      mylogin {
        id
        sub
        name
        phone
        email
        birthday
        gender
        type
        rewardPercentage
        clientDiscount
        clientBonus
      }
    }
    rewardPercentage
    clientDiscount
    clientBonus
  }
}
`;
export const listPartners = `query ListPartners(
  $filter: ModelPartnerFilterInput
  $limit: Int
  $nextToken: String
) {
  listPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sub
      name
      phone
      email
      birthday
      gender
      referrals {
        nextToken
      }
      type
      salesman {
        id
        name
        active
      }
      rewardPercentage
      clientDiscount
      clientBonus
    }
    nextToken
  }
}
`;
export const getReferral = `query GetReferral($id: ID!) {
  getReferral(id: $id) {
    id
    date
    partner {
      id
      sub
      name
      phone
      email
      birthday
      gender
      referrals {
        nextToken
      }
      type
      salesman {
        id
        name
        active
      }
      rewardPercentage
      clientDiscount
      clientBonus
    }
    clientName
    clientPhone
    products {
      items {
        id
      }
      nextToken
    }
    linha
    order {
      id
      date
      referral {
        id
        date
        clientName
        clientPhone
        linha
        obs
        rewardPercentage
        clientDiscount
        clientBonus
      }
      clientName
      clientPhone
      clientCPF
      clientBirthday
      document
      amount
      store {
        id
        name
        address
        phone
        whatsapp
        email
        obs
      }
      salesman {
        id
        name
        active
      }
      obs
      rewardAmount
      payment {
        nextToken
      }
    }
    obs
    rewardPercentage
    clientDiscount
    clientBonus
  }
}
`;
export const listReferrals = `query ListReferrals(
  $filter: ModelReferralFilterInput
  $limit: Int
  $nextToken: String
) {
  listReferrals(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      date
      partner {
        id
        sub
        name
        phone
        email
        birthday
        gender
        type
        rewardPercentage
        clientDiscount
        clientBonus
      }
      clientName
      clientPhone
      products {
        nextToken
      }
      linha
      order {
        id
        date
        clientName
        clientPhone
        clientCPF
        clientBirthday
        document
        amount
        obs
        rewardAmount
      }
      obs
      rewardPercentage
      clientDiscount
      clientBonus
    }
    nextToken
  }
}
`;
export const getOrder = `query GetOrder($id: ID!) {
  getOrder(id: $id) {
    id
    date
    referral {
      id
      date
      partner {
        id
        sub
        name
        phone
        email
        birthday
        gender
        type
        rewardPercentage
        clientDiscount
        clientBonus
      }
      clientName
      clientPhone
      products {
        nextToken
      }
      linha
      order {
        id
        date
        clientName
        clientPhone
        clientCPF
        clientBirthday
        document
        amount
        obs
        rewardAmount
      }
      obs
      rewardPercentage
      clientDiscount
      clientBonus
    }
    clientName
    clientPhone
    clientCPF
    clientBirthday
    document
    amount
    store {
      id
      name
      address
      phone
      whatsapp
      email
      obs
      salesmans {
        nextToken
      }
    }
    salesman {
      id
      name
      mainstore {
        id
        name
        address
        phone
        whatsapp
        email
        obs
      }
      active
      mylogin {
        id
        sub
        name
        phone
        email
        birthday
        gender
        type
        rewardPercentage
        clientDiscount
        clientBonus
      }
    }
    obs
    rewardAmount
    payment {
      items {
        id
      }
      nextToken
    }
  }
}
`;
export const listOrders = `query ListOrders(
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      date
      referral {
        id
        date
        clientName
        clientPhone
        linha
        obs
        rewardPercentage
        clientDiscount
        clientBonus
      }
      clientName
      clientPhone
      clientCPF
      clientBirthday
      document
      amount
      store {
        id
        name
        address
        phone
        whatsapp
        email
        obs
      }
      salesman {
        id
        name
        active
      }
      obs
      rewardAmount
      payment {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getPayment = `query GetPayment($id: ID!) {
  getPayment(id: $id) {
    id
    date
    partner {
      id
      sub
      name
      phone
      email
      birthday
      gender
      referrals {
        nextToken
      }
      type
      salesman {
        id
        name
        active
      }
      rewardPercentage
      clientDiscount
      clientBonus
    }
    orders {
      items {
        id
      }
      nextToken
    }
    paymentAmount
    paymentDescription
    paymentObs
  }
}
`;
export const listPayments = `query ListPayments(
  $filter: ModelPaymentFilterInput
  $limit: Int
  $nextToken: String
) {
  listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      date
      partner {
        id
        sub
        name
        phone
        email
        birthday
        gender
        type
        rewardPercentage
        clientDiscount
        clientBonus
      }
      orders {
        nextToken
      }
      paymentAmount
      paymentDescription
      paymentObs
    }
    nextToken
  }
}
`;
export const getStore = `query GetStore($id: ID!) {
  getStore(id: $id) {
    id
    name
    address
    phone
    whatsapp
    email
    obs
    salesmans {
      items {
        id
        name
        active
      }
      nextToken
    }
  }
}
`;
export const listStores = `query ListStores(
  $filter: ModelStoreFilterInput
  $limit: Int
  $nextToken: String
) {
  listStores(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      address
      phone
      whatsapp
      email
      obs
      salesmans {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getSalesman = `query GetSalesman($id: ID!) {
  getSalesman(id: $id) {
    id
    name
    mainstore {
      id
      name
      address
      phone
      whatsapp
      email
      obs
      salesmans {
        nextToken
      }
    }
    active
    mylogin {
      id
      sub
      name
      phone
      email
      birthday
      gender
      referrals {
        nextToken
      }
      type
      salesman {
        id
        name
        active
      }
      rewardPercentage
      clientDiscount
      clientBonus
    }
  }
}
`;
export const listSalesmans = `query ListSalesmans(
  $filter: ModelSalesmanFilterInput
  $limit: Int
  $nextToken: String
) {
  listSalesmans(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      mainstore {
        id
        name
        address
        phone
        whatsapp
        email
        obs
      }
      active
      mylogin {
        id
        sub
        name
        phone
        email
        birthday
        gender
        type
        rewardPercentage
        clientDiscount
        clientBonus
      }
    }
    nextToken
  }
}
`;
export const getProduct = `query GetProduct($id: ID!) {
  getProduct(id: $id) {
    id
    reference
    description
    imgurl0
    imgurl1
    imgurl2
    material
    compression
    brand
    referral {
      items {
        id
      }
      nextToken
    }
  }
}
`;
export const listProducts = `query ListProducts(
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      reference
      description
      imgurl0
      imgurl1
      imgurl2
      material
      compression
      brand
      referral {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getReferralProducts = `query GetReferralProducts($id: ID!) {
  getReferralProducts(id: $id) {
    id
    product {
      id
      reference
      description
      imgurl0
      imgurl1
      imgurl2
      material
      compression
      brand
      referral {
        nextToken
      }
    }
    referral {
      id
      date
      partner {
        id
        sub
        name
        phone
        email
        birthday
        gender
        type
        rewardPercentage
        clientDiscount
        clientBonus
      }
      clientName
      clientPhone
      products {
        nextToken
      }
      linha
      order {
        id
        date
        clientName
        clientPhone
        clientCPF
        clientBirthday
        document
        amount
        obs
        rewardAmount
      }
      obs
      rewardPercentage
      clientDiscount
      clientBonus
    }
  }
}
`;
export const listReferralProductss = `query ListReferralProductss(
  $filter: ModelReferralProductsFilterInput
  $limit: Int
  $nextToken: String
) {
  listReferralProductss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      product {
        id
        reference
        description
        imgurl0
        imgurl1
        imgurl2
        material
        compression
        brand
      }
      referral {
        id
        date
        clientName
        clientPhone
        linha
        obs
        rewardPercentage
        clientDiscount
        clientBonus
      }
    }
    nextToken
  }
}
`;
export const getPaymentOrders = `query GetPaymentOrders($id: ID!) {
  getPaymentOrders(id: $id) {
    id
    payment {
      id
      date
      partner {
        id
        sub
        name
        phone
        email
        birthday
        gender
        type
        rewardPercentage
        clientDiscount
        clientBonus
      }
      orders {
        nextToken
      }
      paymentAmount
      paymentDescription
      paymentObs
    }
    order {
      id
      date
      referral {
        id
        date
        clientName
        clientPhone
        linha
        obs
        rewardPercentage
        clientDiscount
        clientBonus
      }
      clientName
      clientPhone
      clientCPF
      clientBirthday
      document
      amount
      store {
        id
        name
        address
        phone
        whatsapp
        email
        obs
      }
      salesman {
        id
        name
        active
      }
      obs
      rewardAmount
      payment {
        nextToken
      }
    }
  }
}
`;
export const listPaymentOrderss = `query ListPaymentOrderss(
  $filter: ModelPaymentOrdersFilterInput
  $limit: Int
  $nextToken: String
) {
  listPaymentOrderss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      payment {
        id
        date
        paymentAmount
        paymentDescription
        paymentObs
      }
      order {
        id
        date
        clientName
        clientPhone
        clientCPF
        clientBirthday
        document
        amount
        obs
        rewardAmount
      }
    }
    nextToken
  }
}
`;
export const partnerBySub = `query PartnerBySub(
  $sub: String
  $filter: ModelPartnerFilterInput
  $limit: Int
  $nextToken: String
) {
  PartnerBySub(
    sub: $sub
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sub
      name
      phone
      email
      birthday
      gender
      referrals {
        nextToken
      }
      type
      salesman {
        id
        name
        active
      }
      rewardPercentage
      clientDiscount
      clientBonus
    }
    nextToken
  }
}
`;

export const getReferral2 = `query GetReferral2($id: ID!) {
  getReferral(id: $id) {
      id
      clientName
      clientPhone
      partner {        
        name        
      }
      date
      products {
        items {
          product {
            	reference
          		imgurl0
          }
        }
        nextToken
      }
      linha
      rewardPercentage
      clientDiscount
      clientBonus
      order {
        id
        date
        clientName
        document
        amount
        store {
          id
          name
        }
        salesman {
          id
          name
          active
        }
        obs
        rewardAmount
      }
      obs
    }
}
`;
export const listStores2 = `query ListStores2(
  $filter: ModelStoreFilterInput
  $limit: Int
  $nextToken: String
) {
  listStores(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      salesmans {
        items {
          id
          name
          active
          }
        nextToken
      }
    }
    nextToken
  }
}
`;

import React from 'react';
import './MyReferralDiscount.css';
import { Card } from 'react-bootstrap';
import QRCode from 'qrcode';

class MyReferralDiscount extends React.Component {
  myPercent = 5;
  constructor(props) {
		super(props);
    //console.log('MyReferralDiscount:');
//    console.log(this.props);
    this.state = {referralId: props.id, desconto: (100*props.myReferral.clientDiscount).toFixed(0), order: null};
	}

  async componentDidMount() {
    var canvas1 = document.getElementById('canvas1');
    if (this.props.myReferral)
    {
      if (!this.props.myReferral.order && !this.props.vencido)
      {

          QRCode.toCanvas(canvas1, this.props.myReferral.id, function (error) {});
          if (this.state.desconto === '')
          {
            this.setState({desconto : (100*this.props.myReferral.clientDiscount).toFixed(0) });
            this.props.setRewardPercentage(this.props.myReferral.rewardPercentage);
          }
/*
      new QRCode(document.getElementById('qrcode'), {
          text: this.props.id, //referralId
          width: 110,
          height: 110,
          colorDark: '#543440',
          colorLight: '#ffffff',
          correctLevel: 2 //QRCode.CorrectLevel.H
      });
*/
//      console.log(this.props.myReferral.order);
        //cupom já utilizado
        //console.log('Venda: ' + this.props.myReferral.order.id);
//        document.getElementById("desconto").style.display = "none";
//          this.setState({order:this.props.myReferral.order});

/*        
        document.getElementById("qrcodeutilizado").style.display = "block";
        document.getElementById("qrcodeutilizado").innerHTML = "Desconto já utilizado em " + this.formatDate(this.props.myReferral.order.date);
        document.getElementById("canvas1").style.opacity = 0.1;
*/
        //console.log('desconto utilizado!');
//          this.props.descontoUtilizado(true);
      }
    }

  }

  async componentDidUpdate ()  {
    var canvas1 = document.getElementById('canvas1');
    if (this.props.myReferral)
    {
      if (!this.props.myReferral.order)
      {
          QRCode.toCanvas(canvas1, this.props.myReferral.id, function (error) {});
          if (this.state.desconto === '')
          {
            this.setState({desconto : (100*this.props.myReferral.clientDiscount).toFixed(0) });
            this.props.setRewardPercentage(this.props.myReferral.rewardPercentage);
          }
/*
      new QRCode(document.getElementById('qrcode'), {
          text: this.props.id, //referralId
          width: 110,
          height: 110,
          colorDark: '#543440',
          colorLight: '#ffffff',
          correctLevel: 2 //QRCode.CorrectLevel.H
      });
*/
//      console.log(this.props.myReferral.order);
        //cupom já utilizado
        //console.log('Venda: ' + this.props.myReferral.order.id);
//        document.getElementById("desconto").style.display = "none";
//           this.setState({order:this.props.myReferral.order});
/*
        document.getElementById("qrcodeutilizado").style.display = "block";
        document.getElementById("qrcodeutilizado").innerHTML = "Desconto já utilizado em " + this.formatDate(this.props.myReferral.order.date);

        document.getElementById("canvas1").style.opacity = 0.1;
 */
        //console.log('desconto utilizado!');

  //        this.props.descontoUtilizado(true);
      }
    }
  }

  /*
  formatDate(dt) {
    return(dt.slice(8,10)+'/'+dt.slice(5,7)+'/'+dt.slice(0,4)+' '+(dt.slice(11,13)-3)+':'+dt.slice(14,16));
  }
  */

  formatDate(data) {
    try {
      if (data.length===10)
        data=data+' 00:00'
  //    console.log(data);
      var date = new Date(data);
  //    console.log(date);  
      return(date.toLocaleDateString('pt-BR'));
  //  console.log(fd);
    } catch(e) {
    console.log(e);
    }
  }


  render() {
//    o valor do desconto está na propriedade clientDiscount
//    mas é assincrono, e ao rodar o render, costuma ainda estar nulo
//   precisa resolver isto, e tratar o 0.10 para 10%  ok/
 //   console.log(this.props.myReferral.clientDiscount);

    return(
        (this.props.myReferral.order===null && !this.props.vencido ? 
        <Card style={{ width: 'auto' }}>
          <Card.Body>
            <Card.Text className="text-center">
              <canvas id="canvas1" className="myReferralDiscount__qrcode"></canvas>
            </Card.Text>
          </Card.Body>
            <Card.Footer name="desconto" variant="success" className="text-center">
                    <h5>Vale {this.state.desconto} % de desconto!</h5>
                    <p>Obs.: desconto não cumulativo</p>
                    { (false? //this.props.partnerName?
                    <p class="small">{this.props.partnerName}</p>
                    :
                    <span></span>
                    ) }
            </Card.Footer>
        </Card>
        :
        <Card style={{ width: 'auto' }}>
          <Card.Body>
            <Card.Text className="text-center">
              <span id="qrcodeutilizado" className="myReferralDiscount__label--qr"></span>
            </Card.Text>
          </Card.Body>
            <Card.Footer name="desconto" variant="success" className="text-center">
            {         (this.props.myReferral.order!==null ? 
                  <h5>Desconto utilizado em {this.formatDate(this.props.myReferral.order.date)}</h5>
          :
                  <h5>Desconto vencido!</h5>
          )}
            </Card.Footer>
        </Card>

        )
    );
  }
}
export default MyReferralDiscount;

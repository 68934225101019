//************************************************************************************/
//
//  IMPORTANTE: ARQUIVO COMPARTILHADO COM OS PROJETOS FLYER E INDICA
//              Se atualizar (incluir imagens): 
//              1) Atualizar os dois arquivos (Flyer e Indica)
//              2) Entrar na rota /config para atualizar a tabela Products com os
//                 novos produtos inseridos.  
//              3) Em caso de edição ou exclusão, alterar manualmente no DynamoDB
//
//************************************************************************************/

import logoyoga from './../img/logo_yoga_w100.png';
import logosigvaris from './../img/logo_sigvaris-100px.png';

var cdnpath = 'https://img-produto-yoga-modeladores.s3.sa-east-1.amazonaws.com/';

export const products = [
    {ref:"3019 J", id: "0b3c457b-0c97-40ec-a115-a385686de7ef",                  path:cdnpath+'3019jpre_W1A2049.jpg', description:"Modelador com colchetes",linha:"premium",tag:"", logo: logoyoga, ordem: 10},
    {ref:"3019-3026 AL/3056", id: "55b6ae3a-c400-4e12-a3ab-9a04402b20c2",       path:cdnpath+'3019-3026jpre.jpg', description:"Modelador c/ divisão frontal",linha:"premium",tag:"", logo: logoyoga, ordem: 20},
    {ref:"3019-3041", id: "f883ea78-e064-4bce-8f10-54dc865a2d59",               path:cdnpath+'3019-3041pre.jpg', description:"Modelador alça larga",linha:"premium",tag:"", logo: logoyoga, ordem: 30},
    {ref:"3019 MANGAS", id: "ad651fd8-fed8-428e-97c7-c7b781256d98",             path:cdnpath+'3019mangas_fr_choco.jpg', description: "Modelador com mangas",linha:"premium",tag:"", logo: logoyoga, ordem: 40},
    {ref:"3019 SB", id: "979a1472-4516-4440-b380-90dea9af8d2b",                 path:cdnpath+'3013DB_3019SB.jpg', description:"Modelador sem busto",linha:"premium",tag:"", logo: logoyoga, ordem: 50},
    {ref:"3022 PRE", id: "4df681ba-31d9-4415-bb9b-f66de6d56f85",                path:cdnpath+'3022pre.jpg', description:"Body com colchetes",linha:"premium",tag:"", logo: logoyoga, ordem: 60},
    {ref:"3018 J", id: "16f1e2c6-4f2c-4c14-a275-6dfd27691dab",                  path:cdnpath+'3018j_v1.jpg', description: "Modelador abaixo do joelho",linha:"premium",tag:"", logo: logoyoga, ordem: 70},
    {ref:"3018-3041x", id: "1ecd6fce-2a08-4de4-8b7d-976a02684120",              path:cdnpath+'3018.3041x_fr_choco.jpg', description: "Modelador abaixo joelho e alças largas",linha:"premium",tag:"", logo: logoyoga, ordem: 80},
    {ref:"3018 MANGAS", id: "43edd868-c3c8-4b68-b3f9-7e8a10c5bd57",             path:cdnpath+'3018mangas_fr_choco.jpg', description: "Modelador abaixo joelho com mangas",linha:"premium",tag:"", logo: logoyoga, ordem: 90},
    {ref:"3030 AL 3015", id: "f290643b-7736-47f6-a6e4-9e771026c958",            path:cdnpath+'3030al3015pre.jpg', description:"Sutiã",linha:"premium", tag:"sutia", logo: logoyoga, ordem: 100},
    {ref:"3015 J PRE", id: "e43ff642-51bd-4b3a-8686-2935120a3ff3",              path:cdnpath+'3015j_v1.jpg', description:"Sutiã c/ reforço embaixo do busto",linha:"premium", tag:"sutia", logo: logoyoga, ordem: 110},
    {ref:"3030 PRE", id: "d3807c89-08e9-4391-82d3-477fe11152a8",                path:cdnpath+'3030pre_3004ren.jpg', description:"Sutiã alça larga",linha:"premium", tag:"sutia", logo: logoyoga, ordem: 150},
  //{ref:"3015-3030 PRE", id: "09fe9ba5-e1fd-4fad-8bc7-c8a7ca12d5f3",           path:cdnpath+'', description:"Sutiã alça larga",linha:"premium", tag:"sutia", logo: logoyoga, ordem: 120},
    {ref:"3015 B", id: "14d6c1e4-4b37-447e-a3b9-e4151a90e4de",                  path:cdnpath+'3015b_v1.jpg', description:"Sutiã busto com costura",linha:"premium", tag:"sutia", logo: logoyoga, ordem: 120},
    {ref:"3015 J LAISE", id: "995d1fb4-f84a-4836-8ebe-981c7826a895",            path:cdnpath+'3015jlaise.jpg', description:"Sutiã pós-cirúrgico com renda",linha:"premium", tag:"sutia", logo: logoyoga, ordem: 130},
    {ref:"3015 SH", id: "e7160ade-8ac6-4d1f-8a85-087bc9e48595",                 path:cdnpath+'3030al3015jshpre.jpg', description:"Sutiã com faixa",linha:"premium", tag:"sutia", logo: logoyoga, ordem: 140},
    {ref:"3030 MANGAS", id: "df762901-18fa-4009-9fc8-c232c9e11349",             path:cdnpath+'3030mangas_v1.jpg', description:"Sutiã com mangas",linha:"premium", tag:"sutia", logo: logoyoga, ordem: 160},
    {ref:"3304", id: "daa6cd02-345d-4d78-8732-08eabf0d628b",                    path:cdnpath+'3304_v1.jpg', description: "Sutiã para simastia",linha:"premium", tag:"sutia", logo: logoyoga, ordem: 170},
    {ref:"3050 A", id: "bfb1cbd7-7471-4e9a-a2ee-03bf9536e8cb",                  path:cdnpath+'3050a_v1.jpg', description: "Faixa para mamoplastia",linha:"premium",tag:"", logo: logoyoga, ordem: 180},
    {ref:"3029 F", id: "6985fb5e-0b50-4fcc-a38f-f428e071a4a1",                  path:cdnpath+'3029f_v1.jpg', description: "Calcinha cintura alta aberta na frente",linha:"premium",tag:"", logo: logoyoga, ordem: 190},
    {ref:"3029 L", id: "ef0754cb-2013-46cd-9f55-2290dd74ee63",                  path:cdnpath+'3029abl.jpg', description:"Calcinha cintura alta abertura lateral",linha:"premium",tag:"", logo: logoyoga, ordem: 200},
    {ref:"3026", id: "e9e60e37-2106-4caf-8460-e5ef3a17852a",                    path:cdnpath+'3026.jpg', description:"Bermuda cintura alta aberta na frente",linha:"premium",tag:"", logo: logoyoga, ordem: 210},
    //3026ablado_fr_exclusiva_choco.jpg
    {ref:"3026 LADO", id: "0cb6c633-0e00-4d72-a568-b7200b828951",               path:cdnpath+'3026LADO_LADO_CH.jpg', description: "Bermuda cintura alta abertura lateral",linha:"premium",tag:"", logo: logoyoga, ordem: 220},
    {ref:"3025 AB", id: "96137181-0c41-4507-a53c-4fbb5e1ca5c8",                 path:cdnpath+'3025ab_v1.jpg', description: "Calça cintura alta aberta na frente",linha:"premium",tag:"", logo: logoyoga, ordem: 230},
    {ref:"3004", id: "95b0aaef-d7a0-49cd-b9fb-5a8c802e7a5b",                    path:cdnpath+'3004_v1.jpg', description: "Bermuda",linha:"premium",tag:"", logo: logoyoga, ordem: 240},
    {ref:"3038 E R", id: "2aafdb83-751c-41d7-be4c-94f7a8e2cf30",                path:cdnpath+'3038e_3056SS-10.jpg', description:"Faixa para orelha",linha:"premium",tag:"", logo: logoyoga, ordem: 250},
    {ref:"3038 A", id: "37294e46-6a6c-429f-bea8-7d8ba16d0383",                  path:cdnpath+'3038a_la_choco.jpg', description: "Queixeira / mentoneira",linha:"premium",tag:"", logo: logoyoga, ordem: 260},
    {ref:"3038 D", id: "88544421-3e9b-4c8d-9468-d863f0b081ee",                  path:cdnpath+'3038d_la_choco.jpg', description: "Mentoneira",linha:"premium",tag:"", logo: logoyoga, ordem: 270},
    {ref:"3039 AC", id: "5ca5eff7-125e-474a-843e-55107df8534f",                 path:cdnpath+'3039ac_v1.jpg', description: "Braceletes com velcro nas costas",linha:"premium",tag:"", logo: logoyoga, ordem: 280},
    {ref:"3009-3041 YS AB", id: "19407aaf-42bd-486b-b560-c91d21eb28d4",         path:cdnpath+'3009-3041ysab.jpg', description:"Modelador com pernas masculino",linha:"premium",tag:"masculino", logo: logoyoga, ordem: 300},
    {ref:"3069 A H", id: "439bd705-ea20-4081-9e12-33813fe049c1",                path:cdnpath+'3069ah_3009h.jpg', description:"Camiseta com colchetes masculina",linha:"premium",tag:"masculino", logo: logoyoga, ordem: 310},
    {ref:"3069 H", id: "8c9b38b0-99d8-443b-80a4-2c912590f54e",                  path:cdnpath+'3069H.jpg', description:"Camiseta masculina fechada",linha:"premium",tag:"masculino", logo: logoyoga, ordem: 312},    
    {ref:"3041 H", id: "b10fa64d-b305-46fc-a459-2b45e3e23077",                  path:cdnpath+'3041h_3009h_fr_choco.jpg', description: "Colete masculino",linha:"premium",tag:"masculino", logo: logoyoga, ordem: 320},
    //30623g_fr_choco.jpg
    {ref:"3062-3G", id: "282c7191-c502-4169-bc29-e6181c99c339",                 path:cdnpath+'3062-3-Fem.jpg', description: "Faixa abdominal/toráxica 3 gomos",linha:"premium",tag:"masculino", logo: logoyoga, ordem: 330},
    //{ref:"3062-4G", id: "26a77bef-2b12-4fc6-b8a7-64f87557d221",                 path:cdnpath+'30624g_fr_choco.jpg', description: "Faixa abdominal/toráxica 4 gomos",linha:"premium",tag:"masculino", logo: logoyoga, ordem: 340},
    {ref:"3062-4G", id: "26a77bef-2b12-4fc6-b8a7-64f87557d221",                 path:cdnpath+'3062-4.jpg', description: "Faixa abdominal/toráxica 4 gomos",linha:"premium",tag:"masculino", logo: logoyoga, ordem: 340},
    {ref:"MEIA SIG 400 SAC", id: "8737fd7d-ed60-4bbc-9363-7b8df7ee5372",        path:cdnpath+'400sad_la_branca.jpg', description: "Meia Antitrombo - Panturrilha",linha:"outras", tag:"", logo: logosigvaris, ordem: 350},
    {ref:"MEIA SIG 400 SAF", id: "0903b52a-472c-461a-95f1-af81baf47d39",        path:cdnpath+'400saf_la_branca.jpg', description: "Meia Antitrombo - Meia coxa",linha:"outras", tag:"", logo: logosigvaris, ordem: 360},
    {ref:"1001", id: "184159f6-abe2-49d0-a926-c3a8185eb657",                    path:cdnpath+'7101_fr_choco.jpg', description: "Placa de contensão frontal",linha:"premium", tag:"acessorio", logo: logoyoga, ordem: 370},
    {ref:"1001 A", id: "7be6b5fb-ae1c-4249-939b-79db155e0304",                  path:cdnpath+'7101_fr_choco.jpg', description: "Placa de contensão frontal rígida",linha:"premium", tag:"acessorio", logo: logoyoga, ordem: 372},
    {ref:"1006 H", id: "e86d6ed3-b38c-420b-b0d8-ebce02a845c5",                  path:cdnpath+'7106h_7106ah_3066s.jpg', description: "Placa de contensão (par)",linha:"premium", tag:"acessorio", logo: logoyoga, ordem: 380},
    {ref:"1006 A H", id: "582ba9c8-2485-40fc-ad36-c0c4f837dbb0",                path:cdnpath+'7106h_7106ah_3066s.jpg', description: "Placa de contensão rígida (par)",linha:"premium", tag:"acessorio", logo: logoyoga, ordem: 382},
    {ref:"1006 L", id: "4f3dd108-abb1-4582-a450-7020180ae146",                  path:cdnpath+'7106l_7106al_3066o.jpg', description: "Placa de contensão oval (par)",linha:"premium", tag:"acessorio", logo: logoyoga, ordem: 390},
    {ref:"1006 A L", id: "6f060b5a-0009-4612-a6ca-34c1dbc06273",                path:cdnpath+'7106l_7106al_3066o.jpg', description: "Placa de contensão oval rígida (par)",linha:"premium", tag:"acessorio", logo: logoyoga, ordem: 392},
    {ref:"1006 N", id: "e74c81e5-e59b-49ae-8543-497931dbbbe7",                  path:cdnpath+'7106n_7106an_3066c.jpg', description: "Placa de contensão (par)",linha:"premium", tag:"acessorio", logo: logoyoga, ordem: 400},
    {ref:"1006 A N", id: "7bb10f87-1a55-4545-9ce2-147e9aa984f7",                path:cdnpath+'7106n_7106an_3066c.jpg', description: "Placa de contensão rígida (par)",linha:"premium", tag:"acessorio", logo: logoyoga, ordem: 402},
    {ref:"1007", id: "6b44b88d-8a50-4ce2-8a23-4e5da1d8f7b0",                    path:cdnpath+'7107_3067_fr_bege.jpg', description: "Placa de contensão contorno corporal",linha:"premium", tag:"acessorio", logo: logoyoga, ordem: 410},
    {ref:"1007 A", id: "04d95871-8faf-42e5-878b-8c43ceaddfc0",                  path:cdnpath+'7107_3067_fr_bege.jpg', description: "Placa de contensão contorno corporal rígida",linha:"premium", tag:"acessorio", logo: logoyoga, ordem: 412},
    {ref:"6019 J", id: "8d276950-76d3-4c03-9fc1-899fc9b4f432",                  path:cdnpath+'6019_W1A1229.jpg', description: "Modelador c/ pernas Soft",linha:"soft", tag:"", logo: logoyoga, ordem: 420},
    {ref:"6019/6041", id: "6054afd4-b242-4d87-a0e5-1152675548b2",               path:cdnpath+'6019-6041_W1A1198.jpg', description: "Modelador alça larga Soft",linha:"soft", tag:"", logo: logoyoga, ordem: 430},
    {ref:"6019/6026", id: "855e92ed-62f5-4094-932c-819e3a8edb71",               path:cdnpath+'6019-6026_W1A1128.jpg', description: "Modelador c/ divisão frontal Soft",linha:"soft", tag:"", logo: logoyoga, ordem: 440},
//  {ref:"6025 AB", id: "0861fc95-63e9-4f8d-9b8b-3d2ff77022ef",                 path:cdnpath+'6075-e-6025-AB_W1A0827.jpg', description: "Calça cintura alta Soft",linha:"soft", tag:"", logo: logoyoga, ordem: 450},
    {ref:"6026", id: "d63b6493-5ffa-4fe8-86da-c01edfbf0de5",                    path:cdnpath+'6026_v1.jpg', description: "Bermuda cintura alta Soft",linha:"soft", tag:"", logo: logoyoga, ordem: 460},
    {ref:"6029", id: "3f3099e7-7484-4141-be9c-741de8fc9aae",                    path:cdnpath+'6029abf_v1.jpg', description: "Calcinha cintura alta Soft",linha:"soft", tag:"", logo: logoyoga, ordem: 470},
    {ref:"6022", id: "aa787287-d78b-4400-b0c2-ee4c6275c1ca",                    path:cdnpath+'6022_W1A0930.jpg', description: "Body aberto Soft",linha:"soft", tag:"", logo: logoyoga, ordem: 480},
 // {ref:"6030", id: "16c527c8-c90f-4229-a647-ae3d9fe52451",                    path:cdnpath+'', description: "Sutiã alça larga Soft",linha:"soft", tag:"sutia", logo: logoyoga, ordem: 490},
    {ref:"6015-6030", id: "610e8ce4-c575-4ea2-996b-b6252dd6d4f8",               path:cdnpath+'6015-6030_v1.jpg', description: "Sutiã alça larga Soft",linha:"soft", tag:"sutia", logo: logoyoga, ordem: 500},
    {ref:"6015 J", id: "90714c15-2c54-467e-895b-55ed1e07ca3f",                  path:cdnpath+'6015j_v1.jpg', description: "Sutiã alça regulável Soft",linha:"soft", tag:"sutia", logo: logoyoga, ordem: 510},
    {ref:"6297", id: "1bc9ced0-6ca9-4a17-8911-778604e994cc",                    path:cdnpath+'6297.jpg', description: "Modelador com pernas sem busto aberto",linha:"soft", tag:"", logo: logoyoga, ordem: 520},
//  {ref:"3158", id: "c221122f-09bb-4de6-a4bb-53dbc575e203",                    path:cdnpath+'', description: "Espartilho s/ elástico",linha:"premium", tag:"", logo: logoyoga, ordem: 530},
//  {ref:"3326 B", id: "dcd18774-25ca-4cca-83cf-70ba76471f77",                  path:cdnpath+'', description: "Corselet s/ elástico",linha:"premium", tag:"", logo: logoyoga, ordem: 540},
    {ref:"3075", id: "60b935b7-7fbd-4d29-8bc4-d2d6ff4c6da6",                    path:cdnpath+'3075.jpg', description:"Espartilho com 12 barbatanas",linha:"premium",tag:"", logo: logoyoga, ordem: 545},
    {ref:"3075-2", id: "b78b35f7-dd01-4a0d-a614-b781654ff128",                  path:cdnpath+'3158_PT_3075-2_CH_FRENTE.jpg', description:"Espartilho com 12 barbatanas e 2 elásticos",linha:"premium",tag:"", logo: logoyoga, ordem: 546},
    {ref:"3076 S", id: "b59c7c7b-3479-4248-a9be-2a1f0484a2e5",                  path:cdnpath+'3076S_v1.jpg', description: "Corset com 12 barbatanas",linha:"premium", tag:"", logo: logoyoga, ordem: 540},
    {ref:"3143", id: "2e5d5020-6bb4-4df8-85c8-6667c2108cc2",                    path:cdnpath+'3141_3143_FRENTE_PRETO.jpg', description: "Calcinha goufre até a cintura",linha:"premium", tag:"", logo: logoyoga, ordem: 542},
    {ref:"3311", id: "19cd9008-6a04-4176-8d64-22823103c1ac",                    path:cdnpath+'3311_W1A1515.jpg', description: "Maiô Modelador UV50+",linha:"outras", tag:"", logo: logoyoga, ordem: 550},
    {ref:"3369", id: "83a25464-264c-417f-bf25-7b8e53a669d5",                    path:cdnpath+'3369_v1.jpg', description: "Maiô Modelador",linha:"outras", tag:"", logo: logoyoga, ordem: 560}


];


export function getProductByReference(reference) {
    return products.filter(
        function(products){ return products.ref === reference }
    );
}

export function getProductById(Id) {
    return products.filter(
        function(products){ return products.id === Id }
    );
}

// copiado do projeto Indica que também gerencia os recursos na Nuvem

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:a6614aeb-ac12-4934-bf06-fcc74895d0b5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_5V8jZmbA3",
    "aws_user_pools_web_client_id": "1fc6k84n3gr2njir157ev3pv5e",
    "oauth": {
        "domain": "indicad41cc47a-d41cc47a-pro.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://catalogo.minhacintamodeladora.com.br/",
        "redirectSignOut": "https://catalogo.minhacintamodeladora.com.br/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://pnezu4eqjnbzfj3tw6ebnwzyh4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_IAM"
//    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;

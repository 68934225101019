import React from 'react';
import { CardGroup, Card } from 'react-bootstrap';

export default class ProductsCards extends React.Component {

    //expect to receive the folloing parameters (props):
    // products:
    // array of an object with the folloing attributes:
    //      path
    //      reference

  constructor(props) {
		super(props);

 //       console.log(this.props);

        this.state = {
            products: props.products
        }
	}

    render() {
        if (this.props.products) {
//            console.log(this.props.products);    
            if (this.props.products.length>0)
            {
                return(  
                    <CardGroup>
                        {this.props.products.map((product, i) => { 
                            return(
                            <Card key={i} style={{ width: 'auto' }}>
                            <Card.Img variant="top" src={product.path} />
                            <Card.Footer className="text-center">
                                {product.ref}
                            </Card.Footer>
                            </Card>
                            );
                        })}
                    </CardGroup>
                );        
            }
        }
        return('');
    }
}